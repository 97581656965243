import { Client, gql } from 'urql'
import { StateCreator } from 'zustand'
import { LayoutSlice } from './layout-slice'
import { ToastSlice } from './toast-slice'

export interface TasksSlice {
  openTaskDrawer: boolean
  setOpenTaskDrawer: (open: boolean) => void
  taskCount: number
  setTaskCount: (count: number) => void
  fetchTaskBadgeCount: (clientGraphQL: Client) => Promise<void>
  currentTabRelated: string
  setCurrentTabRelated: (value: string) => void
}

export const createTasksSlice: StateCreator<
  LayoutSlice & ToastSlice & TasksSlice,
  [],
  [],
  TasksSlice
> = (set: Function) => ({
  openTaskDrawer: false,
  setOpenTaskDrawer: (open: boolean) => set({ openTaskDrawer: open }),
  taskCount: 0,
  setTaskCount: (count: number) => set({ taskCount: count }),
  fetchTaskBadgeCount: async (clientGraphQL: Client) => {
    const result = await clientGraphQL.query(
      gql<
        {
          tasksList: {
            metadata: {
              totalCount: number
            }
          }
        },
        {
          page?: number
          limit?: number
          tab: string
          profileId?: number
          applicantId?: number
          filterBy?: string
        }
      >`
        query (
          $page: Int
          $limit: Int
          $tab: String
          $profileId: Int
          $applicantId: Int
          $filterBy: String
        ) {
          tasksList(
            page: $page
            limit: $limit
            tab: $tab
            profileId: $profileId
            applicantId: $applicantId
            filterBy: $filterBy
          ) {
            metadata {
              totalCount
            }
          }
        }
      `,
      { tab: 'my', filterBy: 'uncompleted' }
    )
    const { tasksList } = result.data || {}
    return set({ taskCount: tasksList?.metadata?.totalCount })
  },
  currentTabRelated: '',
  setCurrentTabRelated: (value: string) => set({ currentTabRelated: value })
})
